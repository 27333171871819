import React, { useState } from "react";
import { Button } from "antd";
import {
  ThunderboltOutlined,
  HistoryOutlined,
  UserOutlined,
  RobotOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";
import ModelLogout from "./Logout";
import useLoading from "../Templates/Loading";
import { getUserData } from "../Utils";
function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { Loading, showLoading } = useLoading();
  const { type } = getUserData();
  const [isLogoutModelVisible, setLogoutModelVisible] = useState(false);
  const [activeButton, setActiveButton] = React.useState<string>(
    location.pathname.replaceAll("/", "")
  );
  const showDeleteModal = () => {
    setLogoutModelVisible(true);
  };

  const hideLogoutModel = () => {
    setLogoutModelVisible(false);
  };

  const handleLogout = () => {
    setLogoutModelVisible(false);
    showLoading();
    setTimeout(() => {
      localStorage.clear();
      navigate("/");
      window.location.reload();
    }, 1200);
  };
  const getActiveButtonStyle = (button: string) => {
    return activeButton === button
      ? {
          color: "#1677ff",
          border: "1px solid #1677ff",
          background: "white",
          boxShadow: "0 2px 0 rgba(5, 145, 255, 0.1)",
        }
      : {};
  };

  return (
    <div className="navigation-container">
      <img
        src="corover.png"
        alt="corover logo"
        className="logo"
        onClick={() => navigate("/status-overview")}
      />
      <div className="button-group">
        <Button
          type="text"
          className="buttonOutline"
          style={getActiveButtonStyle("status-overview")}
          icon={<ThunderboltOutlined />}
          onClick={() => {
            setActiveButton("status-overview");
            navigate("/status-overview");
          }}
        >
          Status
        </Button>
        <Button
          type="text"
          style={getActiveButtonStyle("status-history")}
          icon={<HistoryOutlined />}
          onClick={() => {
            setActiveButton("status-history");
            navigate("/status-history");
          }}
        >
          History
        </Button>

        {type === "Admin" && (
          <Button
            type="text"
            style={getActiveButtonStyle("all-users")}
            icon={<UserOutlined />}
            onClick={() => {
              setActiveButton("all-users");
              navigate("/all-users");
            }}
          >
            Users
          </Button>
        )}
        <Button
          type="text"
          style={getActiveButtonStyle("all-bots")}
          icon={<RobotOutlined />}
          onClick={() => {
            setActiveButton("all-bots");
            navigate("/all-bots");
          }}
        >
          Bots
        </Button>

        <Button
          type="text"
          shape="circle"
          onClick={showDeleteModal}
          icon={<LogoutOutlined />}
        />
      </div>
      <ModelLogout
        visible={isLogoutModelVisible}
        onConfirm={() => handleLogout()}
        onCancel={hideLogoutModel}
      />
      <Loading />
    </div>
  );
}

export default Navigation;
