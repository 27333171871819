import React from "react";
import { Modal } from "antd";
import { CloseCircleOutlined, FileOutlined } from "@ant-design/icons";
import { extractFileName } from "../../Utils";

interface CommentsModalProps {
  visible: boolean;
  onCancel: () => void;
  comments: string;
  attachments: string[];
}

const ModelComments: React.FC<CommentsModalProps> = ({
  visible,
  onCancel,
  comments,
  attachments,
}) => {
  const handleAttachmentClick = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Modal
      title="Comments"
      open={visible}
      closeIcon={
        <CloseCircleOutlined style={{ fontSize: "25px" }} onClick={onCancel} />
      }
      footer={null}
      onCancel={onCancel}
    >
      <p>{comments ? comments : "Yet to update"} </p>

      <p style={{ fontSize: "16px", fontWeight: 600 }}> Attachment(s) </p>

      <div>
        {attachments && attachments.length > 0 ? (
          attachments.map((data, index) => (
            <div key={index} style={{ padding: "3px" }}>
              <FileOutlined
                style={{
                  fontSize: "15px",
                  padding: "0px 5px 0px 0px",
                  color: "blue",
                }}
                onClick={() => handleAttachmentClick(data)}
              />
              <span
                onClick={() => handleAttachmentClick(data)}
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
              >
                {extractFileName(data)}
              </span>
            </div>
          ))
        ) : (
          <p style={{ cursor: "not-allowed", color: "gray" }}>No attachments</p>
        )}
      </div>
    </Modal>
  );
};

export default ModelComments;
