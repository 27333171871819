import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { DeleteOutlined, LinkOutlined } from "@ant-design/icons";
import useBotApis from "../../Api/BotApi";
import AddBot from "./AddBot";
import useNotification from "../../Templates/Alert";
import useLoading from "../../Templates/Loading";
import moment from "moment";
import ModelDelete from "../../Templates/Delete";
import { getUserData, statusColor } from "../../Utils";

const BotDataGrid: React.FC = () => {
  const { deleteBotApi, botsData, getBotsApi } = useBotApis();
  const [reloadFlag, setReloadFlag] = useState(false);
  const { openNotification } = useNotification();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const { showLoading, hideLoading, Loading, isLoading } = useLoading();
  const { type } = getUserData();

  const handleDeleteBot = async (id: string) => {
    showLoading();
    setIsDeleteModalVisible(false);
    const response = await deleteBotApi(id);
    if (response.success) {
      setTimeout(() => {
        hideLoading();
        setReloadFlag(!reloadFlag);
        openNotification(response.message, "", "success");
      }, 1000);
    }
  };

  const showDeleteModal = (id: string) => {
    setDeleteID(id);
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  useEffect(() => {
    const fetchBots = async () => {
      showLoading();
      try {
        await getBotsApi();
      } finally {
        hideLoading();
      }
    };
    fetchBots();
  }, [reloadFlag]);

  return (
    <>
      <Loading />
      {!isLoading && (
        <>
          {botsData.length === 0 ? (
            <span
              style={{
                color: "gray",
                display: "flex",
                justifyContent: "center",
                position: "relative",
                top: "50vh",
                cursor: "not-allowed",
              }}
            >
              {botsData.length > 0
                ? `${botsData.length} total bots`
                : `No bots to display.`}
            </span>
          ) : (
            <>
              <div className="userGrid">
                {botsData.map((data, index) => (
                  <div
                    key={index}
                    className="projectRow_"
                    style={{
                      display: "flex",
                      marginBottom: "5px",
                      alignItems: "center",
                    }}
                  >
                    <div className="projectDetails">
                      <span
                        className="statusCircle"
                        style={{
                          backgroundColor: `${statusColor(data.status)}`,
                        }}
                      ></span>
                      <img
                        src={data.botIcon}
                        alt=""
                        width={20}
                        height={20}
                        className="botIcon"
                      />
                      <span style={{ fontWeight: 500, fontSize: "16px" }}>
                        {data.botTitle}
                      </span>
                    </div>

                    <div
                      className="historyStatus"
                      // style={{ display: "flex", alignItems: "center", gap: "20px" }}
                    >
                      <span
                        style={{
                          textAlign: "center",
                          color: "rgb(211, 211, 211)",
                          padding: "0px  10px 0px 10px ",
                          fontSize: "14px",
                        }}
                      >
                        {`Added on ${moment(data.createdAt).format(
                          "DD MMMM YYYY"
                        )}`}
                      </span>
                      <div className="buttonsGroup">
                        <Button
                          size="small"
                          style={{
                            color: "white",
                            backgroundColor: "#1677ff",
                            border: "1px solid #1677ff",
                          }}
                          icon={<LinkOutlined />}
                          onClick={() => window.open(data.accessLink, "_blank")}
                        >
                          Visit Link
                        </Button>
                        {type === "Admin" && (
                          <Button
                            size="small"
                            style={{
                              color: "rgb(170, 0, 0)",
                              backgroundColor: "white",
                              border: "1px solid rgb(170, 0, 0)",
                            }}
                            icon={<DeleteOutlined />}
                            onClick={() => showDeleteModal(data._id)}
                          >
                            Delete
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}

      <ModelDelete
        visible={isDeleteModalVisible}
        onConfirm={() => handleDeleteBot(deleteID)}
        onCancel={hideDeleteModal}
      />

      {type === "Admin" && (
        <AddBot onReload={() => setReloadFlag(!reloadFlag)} />
      )}
    </>
  );
};

export default BotDataGrid;
