import React, { useState } from "react";
import { Modal, Button, Dropdown, Input, Menu, Upload } from "antd";
import {
  DownOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { statusItems } from "../../../Data";
import { AddStatusFormProps } from "../StatusTable/Types";
import { useFormik } from "formik";
import * as Yup from "yup";
import useStatusApi from "../../../Api/StatusApi";
import useNotification from "../../../Templates/Alert";
import useLoading from "../../../Templates/Loading";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserData } from "../../../Utils";

const { TextArea } = Input;

const AddStatusForm: React.FC<AddStatusFormProps> = ({
  handleCancel,
  botOptions,
  onReload,
  name,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBot, setSelectedBot] = useState<string>("Select Bot");
  const [selectedStatus, setSelectedStatus] = useState<string>("Select Status");
  const { openNotification } = useNotification();
  const { showLoading, hideLoading, Loading } = useLoading();
  const { id } = getUserData();
  const { addStatusApi } = useStatusApi();
  const location = useLocation();
  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);
  const maxFiles = 3;
  const formik = useFormik({
    initialValues: {
      comment: "",
      status: "",
      bot: "",
      tester: id,
      attachments: [], // array to store uploaded files
    },
    validationSchema: Yup.object({
      comment: Yup.string()
        .min(100, "Comment must be at least 100 characters")
        .required("Comment is required"),
      status: Yup.string().required("Status is required"),
      bot: Yup.string().required("Bot is required"),
    }),
    onSubmit: async (values) => {
      // Navigate after form submission only if on specific route
      if (location.pathname === "/status-history") {
        navigate("/status-overview");
      }

      showLoading();
      const response = await addStatusApi(values);

      setTimeout(() => {
        hideLoading();
        if (response.success) {
          openNotification(response.message, "", "success");
          onReload();
          handleCancel();
        } else if (response.exist) {
          openNotification(response.message, "", "warning");
        } else {
          openNotification(response.message, "", "error");
        }
      }, 1000);
    },
  });

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const handleStatusMenuSelect = (item: any) => {
    const selectedItem = statusItems.find(
      (status) => status.key === item.key
    )?.label;
    setSelectedStatus(selectedItem || "Select Status");
    formik.setFieldValue("status", selectedItem || "");
  };

  const handleBotsMenuSelect = (item: any) => {
    const selectedLabel = botOptions.find((bot) => bot.key === item.key)?.label;
    setSelectedBot(selectedLabel || "Select Bot");
    formik.setFieldValue("bot", item.key || "");
  };

  const filteredBots = botOptions.filter((item) =>
    item.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
  const allowedVideoTypes = ["video/mp4", "video/quicktime", "video/x-msvideo"];

  const handleUpload = ({ file }: any) => {
    if (counter >= maxFiles) return;

    const MAX_FILE_SIZE = 50 * 1024 * 1024;
    const isImage = allowedImageTypes.includes(file.type);
    const isVideo = allowedVideoTypes.includes(file.type);
    const isValidSize = file.size <= MAX_FILE_SIZE;

    if (!isImage && !isVideo) {
      openNotification(
        "Invalid file type! Only images (jpg, png) and videos (mp4, mov, avi) are allowed.",
        "",
        "error"
      );
      return Upload.LIST_IGNORE;
    }

    if (!isValidSize) {
      openNotification("File size must be less than 50MB!", "", "error");
      return Upload.LIST_IGNORE;
    }

    // Update form values and counter
    formik.setFieldValue("attachments", [...formik.values.attachments, file]);
    setCounter(counter + 1);

    return false;
  };

  const handleRemove = (file: any) => {
    const updatedAttachments = formik.values.attachments.filter(
      (attachment: any) => attachment.uid !== file.uid
    );

    formik.setFieldValue("attachments", updatedAttachments);
    setCounter(counter - 1);
  };

  return (
    <Modal
      centered
      open={true}
      closeIcon={
        <CloseCircleOutlined
          style={{ fontSize: "25px" }}
          onClick={handleCancel}
        />
      }
      width={700}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 0px 0px 0px",
          }}
        >
          <span style={{ fontSize: "14px" }}>
            Notifying as <span style={{ fontWeight: 600 }}>{name} </span>
          </span>
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{
              color: "#1677ff",
              border: "1px solid #1677ff",
              background: "white",
            }}
          >
            Submit
          </Button>
        </div>
      }
    >
      <span style={{ fontSize: "20px", fontWeight: 500 }}>Add Status</span>

      <span>
        <div className="addstatusBtnGroup">
          <span className="addstatusBtn">
            <Dropdown
              overlay={
                <div>
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Search Bot"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Menu style={{ maxHeight: "165px", overflowY: "auto" }}>
                    {filteredBots.length > 0 ? (
                      filteredBots.map((item) => (
                        <Menu.Item
                          key={item.key}
                          onClick={() => handleBotsMenuSelect(item)}
                        >
                          <div
                            style={{
                              padding: "5px 5px",
                              background:
                                formik.values.bot === item.label
                                  ? "rgb(0, 127, 255)"
                                  : "white",
                              color:
                                formik.values.bot === item.label
                                  ? "white"
                                  : "black",
                            }}
                          >
                            {item.label.toUpperCase()}
                          </div>
                        </Menu.Item>
                      ))
                    ) : (
                      <span
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Not Found!
                      </span>
                    )}
                  </Menu>
                </div>
              }
              placement="bottom"
              trigger={["click"]}
            >
              <Button
                icon={<DownOutlined />}
                style={{
                  width: "100%",
                  color: formik.values.bot ? "black" : "gray",
                  fontSize: "16px",
                  fontWeight: 400,
                  justifyContent: "left",
                }}
              >
                {selectedBot}
              </Button>
            </Dropdown>
            {formik.touched.bot && formik.errors.bot && (
              <div className="errorMsg">{formik.errors.bot}</div>
            )}
          </span>

          <span className="addstatusBtn">
            <Dropdown
              overlay={
                <Menu onClick={handleStatusMenuSelect}>
                  {statusItems.slice(1).map((item) => (
                    <Menu.Item key={item.key}>
                      <div style={{ padding: "5px 5px" }}>{item.label}</div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              placement="bottom"
              trigger={["click"]}
            >
              <Button
                icon={<DownOutlined />}
                style={{
                  width: "100%",
                  color: formik.values.status ? "black" : "gray",
                  fontSize: "16px",
                  fontWeight: 400,
                  justifyContent: "left",
                }}
              >
                {selectedStatus}
              </Button>
            </Dropdown>
            {formik.touched.status && formik.errors.status && (
              <div className="errorMsg">{formik.errors.status}</div>
            )}
          </span>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <TextArea
            showCount
            maxLength={3000}
            name="comment"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.comment}
            placeholder={`Add your comments here...\n \nNote: If the bot is currently offline, please indicate who has been informed to investigate the issue. If the bot is operational, describe the steps taken to confirm its functionality. In case of partial functionality, specify what aspects are working and what are not. If the bot is under going maintenance, provide details on the ongoing tasks and the expected time for completion.`}
            style={{
              height: 200,
              resize: "none",
              marginTop: "10px",
              fontSize: "14px",
            }}
          />
          {formik.touched.comment && formik.errors.comment && (
            <div className="errorMsg">{formik.errors.comment}</div>
          )}
        </form>

        <div style={{ padding: "20px 0px 0px 0px" }}>
          <Upload
            name="attachments"
            listType="picture-card"
            beforeUpload={(file) => {
              const isSizeValid = file.size / 1024 / 1024 < 50;
              if (!isSizeValid) {
                openNotification(
                  "File size must be less than 50MB!",
                  "",
                  "error"
                );
                return Upload.LIST_IGNORE;
              }
              handleUpload({ file });
              return false;
            }}
            multiple={false}
            maxCount={maxFiles}
            accept=".jpg,.jpeg,.png,.mp4,.mov,.avi"
            onRemove={(file) => handleRemove(file)} // Custom remove handler
            showUploadList={{ showPreviewIcon: false }}
          >
            <UploadOutlined style={{ fontSize: "35px", color: "gray" }} />
          </Upload>
        </div>

        {counter === maxFiles && (
          <span style={{ color: "gray" }}>
            Max. 3 files can be uploaded, not exceeding 50MB.
          </span>
        )}
      </span>
      <Loading />
    </Modal>
  );
};

export default AddStatusForm;
