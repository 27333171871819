import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import AddStatusForm from "./AddStatusForm";
import { AddStatusProps } from "../StatusTable/Types";
import { getUserData } from "../../../Utils";
const AddStatus: React.FC<AddStatusProps> = ({ botOptions, onReload }) => {
  const [model, setModel] = React.useState<boolean>(false);
  const { name } = getUserData();

  return (
    <>
      {!model ? (
        <FloatButton
          icon={<PlusOutlined style={{ color: "white" }} />}
          type="default"
          onClick={() => setModel(true)}
          style={{
            right: "5%",
            bottom: "10%",
            backgroundColor: "black",
            boxShadow: "4px 3px 5px rgba(0, 0, 0, 0.5)",
          }}
        />
      ) : (
        <>
          <AddStatusForm
            botOptions={botOptions}
            handleCancel={() => setModel(false)}
            onReload={onReload}
            name={name}
          />
        </>
      )}
    </>
  );
};
export default AddStatus;
