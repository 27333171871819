import { useState } from "react";
import axios from "axios";
import {
  StatusDataType,
  addStatusApiType,
} from "../Components/Status/StatusTable/Types";
import { getUserData } from "../Utils";

const useStatusApi = () => {
  const [statsData, setStatsData] = useState<StatusDataType[]>([]);
  const [statsCountData, setStatsCountData] = useState<any>();
  const { token } = getUserData();
  const [loading, setLoading] = useState(false);

  const getStatsApi = async () => {
    const url = `/API/getStats`;
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data) {
        if (response.data.length > 0) setStatsData(response.data);
        setLoading(false);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      setStatsData([]);
    }
  };

  const addStatusApi = async (data: addStatusApiType) => {
    const url = `/API/addStatus`;
    setLoading(true);

    const formData = new FormData();
    
    data.attachments.forEach((attachment: any) => {
        formData.append("attachments", attachment);
    });

    formData.append("bot", data.bot);
    formData.append("comment", data.comment);
    formData.append("status", data.status);
    formData.append("tester", data.tester);

    const headers: any = {
     "Content-Type": "multipart/form-data",
        ...(token && { Authorization: token }),
    };

    try {
        const response = await axios.post(url, formData, { headers });
        if (response.data) {
            setLoading(false);
            return response.data;
        } else {
            return [];
        }
    } catch (error) {
        console.error("Error:", error);
        setLoading(false); 
    }
};


  const getStatsCountApi = async () => {
    const url = `/API/bot/getCount`;
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
      ...(token && { Authorization: token }),
    };
    try {
      const response = await axios.get(url, { headers });
      if (response.data) {
        setStatsCountData(response.data);
        setLoading(false);
        return response.data;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error:", error);
      setStatsCountData([]);
    }
  };
  return {
    getStatsApi,
    statsData,
    addStatusApi,
    getStatsCountApi,
    statsCountData,
    loading,
  };
};

export default useStatusApi;
