import React from "react";
import { StatusCountProps } from "./Types";

const Text: React.FC<StatusCountProps> = ({ color, count, text }) => {
  return (
    <div className="textContainer">
      <span className="statusCircle" style={{ backgroundColor: color }}></span>
      <span>{count ? count : 0}</span>
      <span>{text}</span>
    </div>
  );
};

const StatusCount: React.FC<any> = ({ statsCountData }) => {
  return (
    <div className="statusCountContainer">
      <Text
        color={"#00A023"}
        count={statsCountData && statsCountData["Up"]}
        text={"Up"}
      />
      <Text
        color={"#F37A0B"}
        count={statsCountData && statsCountData["Partially Working"]}
        text={"Partially Working"}
      />
      <Text
        color={"#AF0000"}
        count={statsCountData && statsCountData["Down"]}
        text={"Down"}
      />
      <Text
        color={"#F5CE00"}
        count={statsCountData && statsCountData["Slow Performance"]}
        text={"Slow Performance"}
      />
      <Text
        color={"#0085FF"}
        count={statsCountData && statsCountData["Under Maintenance"]}
        text={"Under Maintenance"}
      />
    </div>
  );
};

export default StatusCount;
