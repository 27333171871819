import React, { useState } from "react";
import AddStatus from "../AddStatus";
import AppFooter from "../../../Templates/Footer";
import { StatusDataGridProps } from "./Types";
import StatusBar from "./StatusBar";
import { MessageFilled, MessageOutlined } from "@ant-design/icons";
import {
  capitalize,
  formatDateStatus,
  getUserData,
  statusColor,
} from "../../../Utils";
import ModelComments from "../../../Templates/Comments";
const StatusDataGrid: React.FC<StatusDataGridProps> = ({
  statusData,
  botOptions,
  onReload,
  loading,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentComments, setCurrentComments] = useState("");
  const [currentAttachments, setCurrentAttachments] = useState<string[]>([]);

  const { type } = getUserData();

  const handleCommentsClick = (comments: string, attachments: string[]) => {
    setCurrentComments(comments);
    setModalVisible(true);
    setCurrentAttachments(attachments);
  };

  return (
    <React.Fragment>
      <div className="statusNhistoryGrid">
        {statusData.map((data, index) => (
          <div
            key={index}
            className="projectRowStatus"
           
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <span
                className="statusCircle"
                style={{
                  backgroundColor: `${statusColor(data.status)}`,
                }}
              ></span>
              <img
                src={data.projectIcon}
                alt=""
                width={20}
                height={20}
                className="botIcon"
              />
              <span style={{ fontWeight: 500, fontSize: "16px" }}>
                {data.project}
              </span>
              <span className="statusNotification">
                {data.updateStatus
                  ? `(Last notified at ${formatDateStatus(
                      data.updateStatus
                    )} by ${data.notifiedBy})`
                  : "Last Notified Time - Not Available"}
              </span>
            </div>
            <div
              className="historyStatus"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  color: `${statusColor(data.status)}`,
                  fontSize: "16px",
                  fontWeight: "500",
                  marginRight: "5px",
                }}
              >
                {data.status ? capitalize(data.status) : "No Status Updated"}
              </span>
              <span className="grayDot"> </span>
              <StatusBar status={data.historyStatus} />

              <span className="grayDot"> </span>
              <MessageFilled
                style={{
                  fontSize: "20px",
                  padding: "0px 3px 0px 3px",
                  color: "#607d8b",
                  cursor: "pointer",
                }}
                onClick={() =>
                  handleCommentsClick(
                    data.comments,
                    data.historyStatus[data.historyStatus.length - 1]
                      .attachments
                  )
                }
              />
            </div>
          </div>
        ))}
      </div>

      {type === "Tester" && (
        <AddStatus botOptions={botOptions} onReload={onReload} />
      )}
      <AppFooter />

      <ModelComments
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        comments={currentComments}
        attachments={currentAttachments}
      />
    </React.Fragment>
  );
};

export default StatusDataGrid;
