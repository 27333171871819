import React, { useEffect, useState } from "react";
import { Button } from "antd";
import {
  DeleteOutlined,
  CopyOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import moment from "moment";
import useUserApis from "../../../Api/UserApi";
import useNotification from "../../../Templates/Alert";
import AddUser from "../AddUser";
import ModelDelete from "../../../Templates/Delete";
import useLoading from "../../../Templates/Loading";

const Testers = () => {
  const [copyBtnLabels, setCopyBtnLabels] = useState<string[]>([]);
  const [copyCss, setCopyCss] = useState<
    { color: string; backgroundColor: string; border: string }[]
  >([]);
  const [reloadFlag, setReloadFlag] = useState<boolean>(false);
  const { showLoading, hideLoading, Loading, isLoading } = useLoading();
  const { getTestersApi, testerData, deleteUserApi, activateUserApi } =
    useUserApis();
  const { openNotification } = useNotification();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const handleOnCopy = (key: string, index: number) => {
    navigator.clipboard.writeText(key);
    openNotification("Key Copied", "", "info");

    setCopyBtnLabels((prevLabels) => {
      const updatedCopyLabels = [...prevLabels];
      updatedCopyLabels[index] = "Copied !";
      return updatedCopyLabels;
    });

    setCopyCss((prevCss) => {
      const updatedCopyCss = [...prevCss];
      updatedCopyCss[index] = {
        color: "#1677ff",
        backgroundColor: "white",
        border: "1px solid #1677ff",
      };
      return updatedCopyCss;
    });

    setTimeout(() => {
      setCopyBtnLabels((prevLabels) => {
        const updatedCopyLabels = [...prevLabels];
        updatedCopyLabels[index] = "Copy Key";
        return updatedCopyLabels;
      });

      setCopyCss((prevCss) => {
        const updatedCopyCss = [...prevCss];
        updatedCopyCss[index] = {
          color: "white",
          backgroundColor: "#1677ff",
          border: "1px solid #1677ff",
        };
        return updatedCopyCss;
      });
    }, 4000);
  };

  const showDeleteModal = (id: string) => {
    setDeleteID(id);
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };
  const handleDeleteUser = async (id: string) => {
    showLoading();
    setIsDeleteModalVisible(false);
    const response = await deleteUserApi({ id: id, type: "tester" });
    if (response.success) {
      setTimeout(() => {
        hideLoading();
        setReloadFlag(!reloadFlag);
        openNotification(response.message, "", "success");
      }, 1000);
    }
  };

  const activateUser = async (id: string) => {
    const response = await activateUserApi({ id: id, type: "tester" });
    if (response.success) {
      setTimeout(() => {
        hideLoading();
        setReloadFlag(!reloadFlag);
        openNotification(response.message, "", "success");
      }, 1000);
    }
  };

  useEffect(() => {
    const fetchStats = async () => {
      showLoading();
      try {
        await getTestersApi();
      } finally {
        hideLoading();
      }
    };

    fetchStats();
  }, [reloadFlag]);

  useEffect(() => {
    const initialCopyLabels = new Array(testerData.length).fill("Copy Key");
    setCopyBtnLabels(initialCopyLabels);

    const initialCopyStyles = new Array(testerData.length).fill({
      color: "white",
      backgroundColor: "#1677ff",
      border: "1px solid #1677ff",
    });
    setCopyCss(initialCopyStyles);
  }, [testerData]);
  return (
    <React.Fragment>
      <Loading />
      {!isLoading && (
        <>
          {testerData.length === 0 ? (
            <span className="userErr">No testers to display.</span>
          ) : (
            <>
              <span
                style={{
                  color: "gray",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0px 30px 0px 30px",
                  position: "relative",
                  top: "95px",
                }}
              >
                {`${testerData.length} testers in total`}
              </span>

              <div className="userGrid">
                {testerData.map((data, index) => (
                  <div
                    key={index}
                    className="projectRow_"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="projectDetails" style={{ gap: "5px" }}>
                      <span
                        className="statusCircle"
                        style={{
                          backgroundColor: data.active ? "green" : "gray",
                        }}
                      ></span>
                      <span style={{ fontWeight: 500, fontSize: "16px" }}>
                        {data.first_name} {data.last_name}
                      </span>
                    </div>
                    <div
                      className="historyStatus"
                      // style={{ display: "flex", alignItems: "center", gap: "20px" }}
                    >
                      <span
                        style={{
                          color: "rgb(211, 211, 211)",
                          padding: "0px  20px 0px 0px ",
                          fontSize: "14px",
                        }}
                      >
                        {data.lastLogin
                          ? `Last login at ${moment(data.lastLogin).format(
                              "DD MMMM YYYY"
                            )}`
                          : "Yet to login"}
                      </span>
                      <div className="buttonsGroup">
                        {data.active ? (
                          <>
                            <Button
                              size="small"
                              style={copyCss[index]}
                              icon={<CopyOutlined />}
                              onClick={() => handleOnCopy(data.key, index)}
                            >
                              {copyBtnLabels[index]}
                            </Button>
                            <Button
                              size="small"
                              style={{
                                color: "rgb(170, 0, 0)",
                                backgroundColor: "white",
                                border: "1px solid rgb(170, 0, 0)",
                              }}
                              icon={<DeleteOutlined />}
                              onClick={() => showDeleteModal(data._id)}
                            >
                              Delete
                            </Button>
                          </>
                        ) : (
                          <Button
                            size="small"
                            style={{
                              color: "#00712D",
                              backgroundColor: "white",
                              border: "1px solid #00712D",
                            }}
                            icon={<UserAddOutlined />}
                            onClick={() => activateUser(data._id)}
                          >
                            Activate
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}

      <ModelDelete
        visible={isDeleteModalVisible}
        onConfirm={() => handleDeleteUser(deleteID)}
        onCancel={hideDeleteModal}
      />
      <AddUser onReload={() => setReloadFlag(!reloadFlag)} />
    </React.Fragment>
  );
};

export default Testers;
